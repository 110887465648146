import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://3fb4a6324655ab517d60e1b04f3865f8@o4508319958630400.ingest.de.sentry.io/4508320101433424',

	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()],

	// disable sentry, when the dev server is running
	enabled: !dev,
	release: dev ? 'hoorai@development' : `hoorai@${__VERSION__}`,
	environment: env.PUBLIC_SENTRY_ENVIRONMENT ?? 'development'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
