import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~6,[2],[3]],
		"/(auth)/auth-error": [25,[4]],
		"/(app)/favorites": [7,[2],[3]],
		"/(app)/notifications": [8,[2],[3]],
		"/(onboarding)/onboarding": [28,[5]],
		"/(app)/profile": [~9,[2],[3]],
		"/(app)/profile/cv-upload": [~10,[2],[3]],
		"/(app)/profile/education": [~11,[2],[3]],
		"/(app)/profile/skills": [~12,[2],[3]],
		"/(app)/projects": [~13,[2],[3]],
		"/(app)/projects/create": [~17,[2],[3]],
		"/(app)/projects/[id]": [~14,[2],[3]],
		"/(app)/projects/[id]/edit": [~15,[2],[3]],
		"/(app)/projects/[id]/public": [~16,[2],[3]],
		"/(app)/sandbox/modals": [18,[2],[3]],
		"/(app)/sandbox/popovers": [19,[2],[3]],
		"/(auth)/signin": [~26,[4]],
		"/(auth)/signout": [~27,[4]],
		"/(app)/survey": [~20,[2],[3]],
		"/(app)/user": [21,[2],[3]],
		"/(app)/user/avatar": [22,[2],[3]],
		"/(app)/user/help": [23,[2],[3]],
		"/(app)/user/settings": [24,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';